<template>
  <div
    class="bg-white w-full max-w-lg text-left rounded-lg overflow-hidden py-5 px-6"
  >
    <div class="w-full mb-6">
      <h4
        class="flex items-center justify-start leading-none text-base font-normal text-brand-primary "
      >
        <Icon class="w-6 h-6 mr-2" name="document-warning" />
        <span>{{ titleMsg }}</span>
      </h4>
    </div>
    <div
      class="flex flex-col items-start justify-start gap-5 text-md font-light leading-normal text-brand-secondary mb-6"
    >
      <p>
       {{ msg }}
      </p>
      <p>
        <b class="font-medium text-brand-primary">Warning:</b> Once your license
        expires, you won’t be able to purchase certain items for this location
        until your new license is approved.
      </p>
    </div>
    <div
      class="flex justify-end items-center w-full space-x-5 text-sm font-light leading-none"
    >
      <a
        target="_blank"
        href="#"
        class="text-brand-secondary hover:text-brand-primary bg-brand-smoke hover:bg-brand-ash hover:bg-opacity-30 rounded-md px-5 py-2.5 border-0 transition-colors"
        >See requirements</a
      >
      <button
        @click="goToPage"
        class="text-white bg-brand-primary rounded-md px-5 py-2.5 border-0 hover:bg-brand-primary hover:bg-opacity-90 transition-colors"
        >Upload</button
      >
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: "ExpiryNotification",
  emits: ["close"],
  props: {
    msg: String,
    titleMsg: String,
  },

  setup(props, { emit }) {
    const router = useRouter();

    const goToPage = () => {
      emit("close");
      router.push("/account/stores");
    };
    return { goToPage };
  },
};
</script>
<style lang="scss" scoped></style>
